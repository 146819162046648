import mapboxgl from "mapbox-gl";
import circle from '@turf/circle';
import { v4 as uuidv4 } from "uuid";
import { customTheme } from "../..";
import { MapIcons, MapLayers } from "../../enums/map";
import { MarkerGeoData } from "../../reducers/states/map";
import cameraIcon from '../../assets/ccCam.png';
import HighwayAlertsDataIcon from '../../assets/person-digging.png';
import { DynamicMapService } from 'mapbox-gl-esri-sources'

// Color Utils
export const trafficColors = {
  low: "#6DCCAC",
  moderate: "#DDC74F",
  heavy: "#EC8D00",
  severe: "#E6002E",
};
export function GetMapLayerColorUtil(layer: string) {
    let color = customTheme.palette.primary.main;
    switch(layer){
        case MapLayers.TRAFFIC_LAYER:
            color = customTheme.marker.TrafficLayer;
            break;   
        case MapLayers.CAMERA_LAYER:
            color = customTheme.marker.CameraLayer;
            break;
        case MapLayers.DISPATCH_EVENTS:
            color = customTheme.marker.DispatchEvent;
            break;
        case MapLayers.ASSOC_DISPATCH_EVENTS:
            color = customTheme.marker.SelectedIncidentDispatches;
            break;
        case MapLayers.VCC_INCIDENTS:
            color = customTheme.marker.ActiveIncident;
            break;
        case MapLayers.CURRENT_INC:
            color = customTheme.marker.SelectedIncident;
            break;
    }

    return color;
}
export const getMarkerColorUtil = (point: MarkerGeoData, defaultColor: string) => {
  if(point.isSelected){
    return customTheme.marker.HighlightedDispatchEvent;
  }
  else if(point.isClosed){
    return customTheme.marker.ClosedDispatchMapIcon;
  }

  return defaultColor;
}

// Camera Layer Utils
export function addCameraSourceUtil(map: any, geoJson: any){
  map.addSource(MapLayers.CAMERA_LAYER, {
      type: 'geojson',
      data: geoJson
    });
}
export function addCameraImageUtil(map: any){
  map.loadImage(cameraIcon, function (error: any, image: any) {
        if (error) throw error;
        map.addImage(MapIcons.CAMERA, image);
      }
    );
}
export function drawCameraLayerUtil(map: any){
  map.addLayer(
    {
      'id': MapLayers.CAMERA_LAYER,
      'type': 'symbol',
      'source': MapLayers.CAMERA_LAYER,
      'layout': {
        'icon-image': MapIcons.CAMERA,
        'icon-size': 0.035
      }
    });
}
export function addCameraLayerListenersUtil(map: any, onClick: (location: string, imgUrl: string) => void){
  // Camera Layer Interaction - Display camera feed on hover and click
  var popup = new mapboxgl.Popup({
      closeButton: false,
      closeOnClick: false
  });

  map.on('click', MapLayers.CAMERA_LAYER, (e: any) => {
    onClick(e.features![0].properties!.LOCATION!, e.features![0].properties!.URL!);
  });

  map.on("mouseenter", MapLayers.CAMERA_LAYER, function (e: any) {
    // Cursor changes for better UX - indicate that these points are different than the rest of the map
    map.getCanvas().style.cursor = "pointer";
    var coordinates = e.features[0].geometry.coordinates.slice();
    var imgUrl: string = e.features[0].properties.URL as string;

    // Populate the popup and set its coordinates based on the feature found.
    popup.setLngLat(coordinates).setHTML("<img src=" + imgUrl + " height='200px' alt='" + imgUrl +"' />").setMaxWidth("500px").addTo(map);
  });

  map.on("mouseleave", MapLayers.CAMERA_LAYER, function () {
    map.getCanvas().style.cursor = "";
    popup.remove();
  });
}

// Traffic Layer Utils
export function addTrafficSourceUtil(map: any){
}

export function drawTrafficLayerUtil(map: any){
  const dynamicSourceId = 'dynamic-source'
  const esriOptions = {
    url: 'https://utility.arcgis.com/usrsvcs/servers/3033ef45438546cca0ce74c6b1db7e17/rest/services/World/Traffic/MapServer/',
    layers: [6],

  }
  if (map.getSource(dynamicSourceId)) {
    map.removeSource(dynamicSourceId);
  }

  const mapService = new DynamicMapService(dynamicSourceId, map, esriOptions);
  map.addLayer({
    'id': MapLayers.TRAFFIC_LAYER,
    'type': 'raster',
    'source': dynamicSourceId,
    });
}


///////////////////////////////////////// Traveler Info Data/////////////////////////////////////////
export function addTravelerInfoDataImageUtil(map: any){
  map.loadImage(HighwayAlertsDataIcon, function (error: any, image: any) {
        if (error) {
          console.log("error loading construction image");
          throw error
        }
        map.addImage(MapIcons.HIGHWAY_ALERT_DATA, image);
      }
    );
}
export function drawTravelerInfoDataUtil(map: any){
  map.addLayer(
    {
      'id': MapLayers.HIGHWAY_ALERTS_DATA,
      'type': 'symbol',
      'source': MapLayers.HIGHWAY_ALERTS_DATA,
      'layout': {
        'icon-image': MapIcons.HIGHWAY_ALERT_DATA,
        'icon-size': 0.035
      }
    });
}

export function addTravelerInfoDataListenersUtil(map: any){
  // Camera Layer Interaction - Display camera feed on hover and click
  var popup = new mapboxgl.Popup({
    closeButton: false,
    closeOnClick: false
  });

  map.on("mouseenter", MapLayers.HIGHWAY_ALERTS_DATA, function (e: any) {
    // Cursor changes for better UX - indicate that these points are different than the rest of the map
    map.getCanvas().style.cursor = "pointer";
    var coordinates = e.features[0].geometry.coordinates.slice();

    var description: string = e.features[0].properties.description as string;
    var severity: string = e.features[0].properties.severity as string;

    // // Populate the popup and set its coordinates based on the feature found.
    popup.setLngLat(coordinates).setHTML(getTrafficIncidentHtml(description, severity)).setMaxWidth("200px").addTo(map);
  });

  map.on("mouseleave", MapLayers.HIGHWAY_ALERTS_DATA, function () {
    map.getCanvas().style.cursor = "";
    popup.remove();
  });


}

function getTrafficIncidentHtml(description: string, severity: string){
  return "<p><b>Description: </b>" + description + "</p></br><p><b>Severity: </b>" + severity + "</p>" ;
}


// Marker Utils
export const createMarkerUtil = (map: any, point: MarkerGeoData, color: string, onClick: (markerType: string, markerId: string) => void) => {
  var marker: mapboxgl.Marker = new mapboxgl.Marker({color: getMarkerColorUtil(point, color)})
    .setLngLat([point.longitude, point.latitude])
  var popup: mapboxgl.Popup = new mapboxgl.Popup({ offset: 30, closeButton: false })
    .setText(point.description) 
    .setLngLat([point.longitude, point.latitude]);
  
  marker.getElement().addEventListener('mouseenter', () => {
      map.getCanvas().style.cursor = "pointer";
      popup.addTo(map);
  });
  
  marker.getElement().addEventListener('mouseleave', () => {
      map.getCanvas().style.cursor = "";
      popup.remove();
  });
  
  marker.getElement().addEventListener('click', () => onClick(point.markerType, point.entityId)); 

  return marker;
}

// Init the source layers present for the lifecycle of the map
export function addEmptySourceUtil(map: any, sourceId: string){
    // Init empty feature collection
    map.addSource( sourceId, {
      type: 'geojson',
      data: {
        type: 'FeatureCollection',
        features: []
       }
    });
}

// Use when adding new or loading geometry to the map, can use the cameras like this too
export function updateAnnotationSourceUtil(map: any, sourceId: string, features: any[]){
  let source;
  
  switch(sourceId){
    case MapLayers.ANNOTATIONS_PERIMETER:
      source = map.getSource(MapLayers.ANNOTATIONS_PERIMETER);
      if(source){
        map.getSource(MapLayers.ANNOTATIONS_PERIMETER).setData({
            'type': 'FeatureCollection',
            'features': features
        });
      }
      break;
    case MapLayers.ANNOTATIONS_DETOUR:
      source = map.getSource(MapLayers.ANNOTATIONS_DETOUR);
      if(source){
        map.getSource(MapLayers.ANNOTATIONS_DETOUR).setData({
            'type': 'FeatureCollection',
            'features': features
        });
      }
      break;
    case MapLayers.ANNOTATIONS_BLOCKAGE:
      if(map.getSource(MapLayers.ANNOTATIONS_BLOCKAGE)){
        map.getSource(MapLayers.ANNOTATIONS_BLOCKAGE).setData({
            'type': 'FeatureCollection',
            'features': features
        });
      }
      break;
    case MapLayers.CONSTRUCTION_TRAFFIC_DATA:
    if(map.getSource(MapLayers.CONSTRUCTION_TRAFFIC_DATA)){
      map.getSource(MapLayers.CONSTRUCTION_TRAFFIC_DATA).setData({
          'type': 'FeatureCollection',
          'features': features
      });
    }
    break;
    case MapLayers.HIGHWAY_ALERTS_DATA:
      if(map.getSource(MapLayers.HIGHWAY_ALERTS_DATA)){
        map.getSource(MapLayers.HIGHWAY_ALERTS_DATA).setData({
            'type': 'FeatureCollection',
            'features': features
        });
      }
      break;
  }
}

// Use to Toggle On annotation map layers
export function drawAnnotationLayerUtil(map: any, layerId: any){
  switch(layerId){
    case MapLayers.ANNOTATIONS_PERIMETER:
      map.addLayer(
        {
          'id': MapLayers.ANNOTATIONS_PERIMETER,
          'type': 'fill',
          'source': MapLayers.ANNOTATIONS_PERIMETER,
          'paint': {
            'fill-color': '#0080ff',
            'fill-opacity': 0.5
          }
        });
        addAnnotationLayerListenersUtil(map, MapLayers.ANNOTATIONS_PERIMETER);
        break;
    case MapLayers.ANNOTATIONS_DETOUR:
      map.addLayer(
        {
          'id': MapLayers.ANNOTATIONS_DETOUR,
          'type': 'line',
          'source': MapLayers.ANNOTATIONS_DETOUR,
          'paint': {
            'line-color': '#0080ff',
            'line-width': 5
          }
        });
      addAnnotationLayerListenersUtil(map, MapLayers.ANNOTATIONS_DETOUR);
      break;
    case MapLayers.ANNOTATIONS_BLOCKAGE:
      map.addLayer(
        {
          'id': MapLayers.ANNOTATIONS_BLOCKAGE,
          'type': 'line',
          'source': MapLayers.ANNOTATIONS_BLOCKAGE,
          'paint': {
            'line-color': '#eb3443',
            'line-width': 5
          }
        });
      addAnnotationLayerListenersUtil(map, MapLayers.ANNOTATIONS_BLOCKAGE);
      break;
    }
}

function addAnnotationLayerListenersUtil(map: any, layerId: any){
  // Hover Info on Annotation Layers
  let popup = new mapboxgl.Popup();
  map.on("mouseenter", layerId, function (e: any) {
    map.getCanvas().style.cursor = "pointer";
    popup.setLngLat(e.lngLat).setHTML(
      "<h3>"  + e.features[0].properties.description + " </h3>"
      + "<p>" + (e.features[0].properties.note?? "") + "</p>").addTo(map);
  });
  
  map.on("mouseleave", layerId, function () {
    map.getCanvas().style.cursor = "";
    popup.remove();
  });
}

export function createCircleFromPtUtil(geoJsonPoint: any, radius: number): GeoJSON.Feature{
  var center = geoJsonPoint.geometry.coordinates;
  var radiusKm = convertMilesToKilometers(radius);
  return {...circle(center, radiusKm), id: uuidv4()};
}

function convertMilesToKilometers(miles: number): number{
  return miles * 1.609344;
}